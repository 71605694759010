<template>
  <div class="row">
    <div class="cover-ribbon" v-if="ribbon">
      <div class="cover-ribbon-inside" title="новый код">new</div>
    </div>
    <load-block :load="load"></load-block>
    <div class="col-12 mintableH">
      <card :title="titleTable" :class="{ noTitle: noTitle }">
        <slot></slot>
        <the-search-element
          :paramsPaginator="paramsPaginator"
          @set-search="$emit('set-search', $event)"
          :linkCreate="linkCreate"
          :forceIsSearch="forceIsSearch"
          :forceIsCreate="
            forceIsCreate &&
            (readOnlyIsActive ? hasReadOnlyInTable : true) &&
            (configRole ? configRole.create : isCreate)"
        />
        <div>
          <div class="col-sm-12">
            <el-table
              :class="classHelp"
              :expand-row-keys="expandRowKeys"
              :row-key="rowKey?rowKey:null"
              @sort-change="beforeSetSort"
              style="width: 100%"
              :data="tableData"
              :row-class-name="() => 'padding'"
              v-if="tableColumns"
              :empty-text="$t('Нет данных!')"
            >

              <el-table-column type="expand" v-if="customExpand">
                <template #default="props">
                    <expand-block :row="props.row" :data-expand="dataExpand(props.row)" :updateData="updateData" ></expand-block>
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :sortable="sortable"
                :prop="column.prop"
                :label="column.label"
                :class-name="hasFieldInData(column.prop) ? '' : 'errortable'"
              >
                <template slot-scope="props">
                  <template>
                    {{
                      column.prop in props.row
                        ? props.row[column.prop]
                        : $t("ОШИБКА! Данные для поля не найдены")
                    }}
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                :min-width="180"
                :width="wAction"
                :lineHeight="lineHeight"
                fixed="right"
                align="right"
                :label="$t(textAction)"
                class="noHiddenText"
                class-name="noHidden"
                v-if="hasAction"
              >
                <template slot-scope="props">
                  <button
                    v-if="tokenIcon"
                    v-tooltip="$t('Заблокировать')"
                    class="btn-danger btn-simple  tableBtn expandbtn"
                    @click="lockToken(props.row)"><i class="nc-icon nc-lock-circle-open"></i>
                  </button>
                  <button
                    v-if="deleteLink"
                    class="btn-danger btn-simple btn-link tableBtn"
                    @click="handleDeleteLink(props.row)"
                  ><i
                    class="table_icon icon_delete"
                    v-tooltip="$t('Удалить')"
                  ></i
                  ></button>


                  <button
                    v-if="customExpand"
                    v-tooltip="$t('ListOrganizations')"
                    class="btn-danger btn-simple  tableBtn expandbtn"
                    @click="toggleExpand(props.row)"><i class="nc-icon nc-bullet-list-67"></i>
                  </button>

                  <router-link
                    class="btn-success btn-simple btn-link tableBtn mr-2"
                    v-if="isDopLinkPluse"
                    :to="customDopLink(props.row)"
                    ><i
                      class="table_icon icon-plus"
                      v-tooltip="$t(plusLinkTitle)"
                    ></i>
                  </router-link>

                  <router-link
                    v-if="
                      forceIsShow && (configRole ? configRole.show : isShow)
                    "
                    class="btn-info btn-simple btn-link tableBtn mr-2"
                    :to="`${prefixShow ? prefixShow : $route.path}/${
                      props.row[fieldId]
                    }/show`"
                    ><i
                      class="table_icon icon_eye"
                      v-tooltip="$t('Посмотреть')"
                    ></i>
                  </router-link>
                  <router-link
                    v-if="!hasCustomEdit &&
                      forceIsEdit &&
                      hasReadOnlyInTableRow(props.row) &&
                      (configRole ? configRole.edit : isEdit)
                    "
                    class="btn-warning btn-simple btn-link tableBtn mr-2"
                    :to="`${prefixEdit ? prefixEdit : $route.path}/${
                      props.row[fieldId]
                    }/edit`"
                    ><i
                      class="table_icon icon_edit"
                      v-tooltip="$t('Редактировать')"
                    ></i>
                  </router-link>

                  <button
                    v-if="hasCustomEdit && forceIsEdit"
                    class="btn-danger btn-simple btn-link tableBtn"
                    @click="customEdit(props.row)"><i
                    class="table_icon icon_edit"
                    v-tooltip="$t('Редактировать')"
                  ></i>
                  </button>

                  <a
                    v-if="
                      forceDelete &&
                      hasReadOnlyInTableRow(props.row) &&
                      (configRole ? configRole.delete : isDelete)
                    "
                    class="btn-danger btn-simple btn-link tableBtn"
                    @click="handleDelete(props.row[fieldId], props.row)"
                    ><i
                      class="table_icon icon_delete"
                      v-tooltip="$t('Удалить')"
                    ></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12">
          <the-paginator
            :paramsPaginator="paramsPaginator"
            @set-perpage="$emit('set-perPage', $event)"
            @set-page="$emit('set-page', $event)"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import LoadBlock from "../../shared/LoadBlock.vue";
import swal from "sweetalert2";
import TheSearchElement from "./tableElement/TheSearchElement";
import ThePaginator from "./tableElement/ThePaginator.vue";
import TheDatePicker from "./tableElement/TheDatePicker.vue";
import { NAME_ACTION } from "../../../RoleConstanans";
import ExpandBlock from "@/components/v2/module/user/ExpandBlock.vue";
/**
 * todo
 * разбить каждую кнопку на компонент и вынести их
 * todo
 * больше декомпозии
 * todo
 * добавить описание
 */
export default {
  name: "TheTable",
  components: {
    ExpandBlock,
    LoadBlock,
    TheSearchElement,
    ThePaginator,
    TheDatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,

  },
  props: {
    hasCustomEdit: { type: Boolean,default: false },
    customEdit: { type: Function },
    classHelp: { type: String },
    deleteLink: { type: Boolean,default: false },
    handleDeleteLink: { type: Function },
    refreshToken: { type: Function },
    lockToken: { type: Function },
    tokenIcon: { type: Boolean,default: false },
    onExpand: { type: Function },
    dataExpand: { type: Function },
    customExpand: { type: Boolean, default: false },
    rowKey: { type: String }, //ключ
    titleTable: { type: String }, //заголовок
    noTitle: { type: Boolean, default: false }, //без заголовка
    tableData: { required: true }, //данные
    fieldInfoList: { type: Array, required: true }, //поля
    paramsPaginator: { type: Object, required: true },
    //option
    load: { type: Boolean, default: false }, //лоудер
    sortable: { default: true }, //признак сортировки
    //префиксы
    fieldId: { type: String, default: "refEntityId" }, //ключ
    prefixEdit: {}, //префикс для ссылки
    prefixShow: {}, //префикс для ссылки
    linkCreate: {}, //префикс для ссылки создать
    //раздел действий
    wAction: { type: Number, default: 170 },
    lineHeight: { type: Number, default: 10 },
    hasAction: { default: true },
    textAction: { type: String, default: "Действия" },
    deleteEl: { type: Function },

    forceIsCreate: {
      // доступна кнопка создать
      type: Boolean,
      default: true,
    },
    forceDelete: {
      // доступна кнопка удалить
      type: Boolean,
      default: true,
    },
    forceIsEdit: {
      // доступна кнопка удалить
      type: Boolean,
      default: true,
    },
    forceIsShow: {
      // доступна кнопка удалить
      type: Boolean,
      default: true,
    },

    isDopLinkPluse: { type: Boolean, default: false }, // дополнительная ссылка
    customDopLink: { type: Function },
    plusLinkTitle: { type: String, default: "" },

    roleName: { type: String }, //название роли
    isShow: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: true },
    isCreate: { type: Boolean, default: true },
    isDelete: { type: Boolean, default: true },
    readOnlyIsActive: { type: Boolean, default: true },
    hasReadOnlyInTable: { type: Boolean, default: true }, // если false то не должно быть кнопки создать по признаку ReadOnly
    forceIsSearch: { type: Boolean, default: true }
  },
  computed: {
    tableColumns() {
      const fieldInfoList = this.fieldInfoList;
      let tableColumns = fieldInfoList
        ? fieldInfoList.map((el) => {
            return {
              prop: el.code,
              label: el.name,
              minWidth: el.sizePercent + "%",
            };
          })
        : [];
      return tableColumns;
    },
    isAdminRole() {
      // показывает что ты админ
      return this.$store.getters.isAdminRole;
    },
    userRoleLouded() {
      //дает все роли
      return this.$store.getters.userRoleLouded;
    },

    configRole() {
      // получния конфига
      const userRoleLouded = this.userRoleLouded;
      const isActionAccess = this.isActionAccess;
      const roleName = this.roleName;
      if (userRoleLouded) {
        return {
          create: isActionAccess(roleName, NAME_ACTION.isCreate),
          show: isActionAccess(roleName, NAME_ACTION.isRead),
          edit: isActionAccess(roleName, NAME_ACTION.isEdit),
          delete: isActionAccess(roleName, NAME_ACTION.isDelete),
        };
      }
      return null;
    },
  },
  data() {
    return {

      expandRowKeys: [],
      perPageOptions: [5, 10, 20, 50],
      ribbon: false,
    };
  },
  methods: {
    updateData(row){
      this.onExpand(row);
    },
    toggleExpand(row) {

      if(this.expandRowKeys.includes(row.userId)) {
        this.expandRowKeys = this.expandRowKeys.filter((el) => el !== row.userId);
      } else {
        this.onExpand(row);
        this.expandRowKeys.push(row.userId);
      }
    },

    isActionAccess(name, action) {
      //метод проверки доступности кнопки
      let isActionAccess = this.$store.getters.isActionAccess;
      return isActionAccess(name, action);
    },
    hasReadOnlyInTableRow(row) {
      // можно показать кнопку на основе данных
      if (this.isAdminRole) {
        return true;
      }

      if (this.readOnlyIsActive && "readOnly" in row) {
        return !row.readOnly;
      }
      return true;
    },

    hasFieldInData(field) {
      if (this.tableData && this.tableData.length > 0) {
        return this.tableData[0] && field in this.tableData[0] ? true : false;
      }
      return true;
    },
    handleDelete(id, row) {
      if (this.noInfoDel) {
        this.deleteEl(id, row);
      } else {
        swal({
          title: this.$t("Вы уверены?"),
          text: this.$t("Вы не сможете вернуть это!"),
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: this.$t("Да удалить!"),
          cancelButtonText: this.$t("Закрыть"),
          buttonsStyling: false,
        }).then((res) => {
          if (res.value && res.value == true) {
            this.deleteEl(id, row);
          }
        });
      }
    },
    beforeSetSort(val) {
      const sortData = {
        prop: val.prop,
        order: val.order,
      };
      this.$emit("set-sort", val);
    },
  },
  created() {
    if (location.hostname == "localhost") {
      this.ribbon = true;
    }
  },
};
</script>
