<template>
  <common-page-table
    deleteLink
    v-if="paramsGetData.UserId"
    :handleDeleteLink="beforeDelete"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :apiDeleteName="''"
    :titleTable="$t('Токены для организаций')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :keyTable="'attrEntityId'"
    :filterField="filterField"

    :force-delete="false"
    :forceIsCreate="false"
    :forceIsEdit="false"
    :force-is-show="false"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
  />
</template>
<script>
import {NAME_OBJECT} from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import {API_METHODS} from "../../../../constant";
import TheTable from "@/components/v2/commonElement/TheTable.vue";
import {ApiService} from "@/util/ApiService";
import swal from "sweetalert2";
import convertLangDataToTable from "@/helpers/converters/convertLangDataToTable";


export default {
  components: {TheTable, CommonPageTable},
  data() {
    return {
      fieldLang: '',
      apiName: API_METHODS.GET_ACCESS_ORG_BY_USER_ID,
    };
  },
  computed: {
    paramsGetData() {
      console.log(this.$route.params)
      return {UserId: this.$route.params['UserId']};
    },
    nameRoleEntity() {
      return NAME_OBJECT.Admin
    },
  },
  methods: {
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return { ...item, isUserActive: item.isUserActive ?this.$t('Активна'):this.$t("Не активна") };
        });
        return tempdataNow;
      }

      return [];
    },



    filterField(fields) {
      const noFieldCode = ['userId', 'userRole', 'userFirstName', 'userEmail']
      return fields.filter(item => !noFieldCode.includes(item.code))
    },
    async onExpand(row) {
      const params = {
        self: this,
        userId: row.userId,
        saveData: {},
        query: {
          lang: this.$store.state.common.lang
        }
      };
      const res = await ApiService(API_METHODS.GET_UserCompany, params);
      this.$set(this.loadDataCompany, row.userId, res.object);
    },

    async beforeDelete(row) {
      const res = await swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false,
      })


      if (res.value && res.value == true) {
        await this.handleDeleteLink(row);
      }

    },

    async handleDeleteLink(row) {
      const params = {
        self: this,
        userCompanyId: row.userCompanyId,
        query: {
          lang: this.$store.state.common.lang
        }
      };
      await ApiService(API_METHODS.DELETE_AccesCompany, params);
    }
  }
};
</script>
