import {API_METHODS} from "../../constant";

export const syncCorteosApi = (
  _getAll,
  _get,
  _create,
  _update,
  _delete,
  _getNoError,
  _createNoError,
  paramsIn
) => {
  return {
    [API_METHODS.GET_SYNC_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization/${paramsIn.OrganizationId}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_SYNC_ALL_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_SYNC_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_SYNC_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_SYNC_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization/${paramsIn.id}`;
      return _delete(urlReq);
    },
    [API_METHODS.CUSTOM_SEARHCH_FOR_SYNC_CORTEOS_ORG]: () => {
      let urlReq = `/CorteosManualSynchronization/Find`;
      return _get(urlReq, { search: paramsIn.search });
    },
    [API_METHODS.CUSTOM_SEARCH_ORG_FOR_ADD_USER_TOKEN]: () => {
      let urlReq = `/Company/Client`;
      return _get(urlReq, { search: paramsIn.search });
    },

    [API_METHODS.ACCESS_ORG]: () => {
      let urlReq = `/Contract/AllowedContracts`;
      return _createNoError(urlReq);
    },

    [API_METHODS.GET_ACCESS_ORG]: () => {
      let urlReq = `/Contract/AllowedContracts`;
      return _getNoError(urlReq);
    },

  }
}
