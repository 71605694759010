import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'

const TablePage = () => import ('./TablePageToken.vue')
export default {
    path: '/tokens',
    component: DashboardLayout,
    children: [{
            path: '/',
            name: 'TokenPage',
            component: TablePage
        }
    ]
}
