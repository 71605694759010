<template>
  <div>
    <RadioGroup v-model="radio1" size="small" @change="handleChange">
      <RadioButton :label="1">{{ $t('All') }}</RadioButton>
      <RadioButton :label="2">{{ $t('AdminUser') }}</RadioButton>
      <RadioButton :label="3">{{ $t('OnlyUser') }}</RadioButton>
    </RadioGroup>
    <common-page-table
      v-if="paramsGetData.CompanyId && reInit"
      deleteLink
      :handleDeleteLink="beforeDelete"
      :apiName="apiName"
      :nameRole="nameRoleEntity"
      :apiDeleteName="''"
      :titleTable="$t('Токены для пользователей')"
      :paramsGetData="paramsGetData"
      :fieldLand="fieldLang"
      :hasLang="false"
      :keyTable="'attrEntityId'"
      :filterField="filterField"
      :alternativeConverter="alternativeConverter"
      :isDefaultConverter="false"
      :force-delete="false"
      :forceIsCreate="false"
      :forceIsEdit="false"
      :force-is-show="false"
    />
  </div>

</template>
<script>
import {NAME_OBJECT} from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import {API_METHODS} from "../../../../constant";
import TheTable from "@/components/v2/commonElement/TheTable.vue";
import {ApiService} from "@/util/ApiService";
import swal from "sweetalert2";

import {Select, Option, Input, Radio, RadioGroup, RadioButton} from "element-ui";
import convertLangDataToTable from "@/helpers/converters/convertLangDataToTable";

export default {
  components: {TheTable, CommonPageTable, RadioGroup, RadioButton},
  data() {
    return {
      reInit: true,
      ForUserWithAllAccess: true,
      ForLinkedUserCompany: true,
      radio1: 1,
      fieldLang: '',
      apiName: API_METHODS.GET_ACCESS_USER_BY_ORG_ID,
    };
  },
  computed: {
    paramsGetData() {
      return {
        CompanyId: this.$route.params['CompanyId'], dopParams: {
          ForUserWithAllAccess: this.ForUserWithAllAccess, ForLinkedUserCompany: this.ForLinkedUserCompany
        }
      };
    },
    nameRoleEntity() {
      return NAME_OBJECT.Admin
    },
  },
  methods: {
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return { ...item, isUserActive: item.isUserActive ?this.$t('Активна'):this.$t("Не активна") };
        });
        return tempdataNow;
      }

      return [];
    },
    handleChange(val) {
      this.ForUserWithAllAccess = (val === 1 || val === 2);
      this.ForLinkedUserCompany = (val === 1 || val === 3);
      this.reInit = false;
      this.$nextTick(() => {
        this.reInit = true;
      })
    },
    filterField(fields) {
      const noFieldCode = ["companyKpp", "companyInn", "companyName", "subjectCompanyId"]
      return fields.filter(item => !noFieldCode.includes(item.code))
    },
    async beforeDelete(row) {
      const res = await swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false,
      })


      if (res.value && res.value == true) {
        await this.handleDeleteLink(row);
      }

    },

    async handleDeleteLink(row) {
      const params = {
        self: this,
        userCompanyId: row.userCompanyId,
        query: {
          lang: this.$store.state.common.lang
        }
      };
      await ApiService(API_METHODS.DELETE_AccesCompany, params);
    }
  }
};
</script>
