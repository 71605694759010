<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="ClientShow"
    routeNameCreate="ClientCreate"
    :keyRoute="'CompanyId'"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"

    :forceNoShowEdit="true"
  >
    <template v-slot:block2>
        <company-data
          :isCreditCard="true"
          :loadDataIn="loadData"
          :isActiveHrFeed="true"
          v-if="loadData"
          :activeShowMenu="true">
        </company-data>
    </template>
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_COMPANY } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from "../../commonElement/CommonPageEdit.vue";
import CompanyData from "../company/CompanyData.vue";

import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";

export default {
  name: "ClientEditPageDynamic",
  components: {
    CommonPageEdit,
    CompanyData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "ClientShow",
      routeNameCreate: "ClientCreate",

      pathBack: "/company/client",
      apiNameGet: API_METHODS.GET_COMPANY,
      apiNameCreate: API_METHODS.CREATE_COMPANY,
      apiNameUpdate: API_METHODS.UPDATE_COMPANY,
      loadData: null
    };
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр клиента");
      let text2 = this.$t("Создание клиента");
      let text3 = this.$t("Редактирование клиента");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.clients;
    },
    paramsSend() {
      return {
        id: this.$route.params.CompanyId || "0",
        typeCompany: "Client"
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      let findIndexOgrn = fieldInfoList.findIndex(item => item.code == "psrn");
      let findIndexkpp = fieldInfoList.findIndex(item => item.code == "kpp");
      const temp = {...fieldInfoList[findIndexOgrn]};
      fieldInfoList[findIndexOgrn] =  fieldInfoList[findIndexkpp]
      fieldInfoList[findIndexkpp] = temp;


      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        langFieldName: FIELD_LANG_COMPANY
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.CompanyId,
      };
    }
  }
};
</script>
