/**
 * Список всех обьект согласно схеме
 * + значит есть в системе
 */
export const NAME_OBJECT = {
  EventLog: "EventLog", //журнал событий +
  Reference: "Reference", // справочнк обший +
  ReferenceSystem: "ReferenceSystem", //  справочник систмемы +
  Esb: "Esb", //шина +
  Admin: "Admin", //Админ, +

  CompanyAddress: "CompanyAddress",

  attrEntity: "Attr", // Атрибуты +
  attrValue: "attrValue",

  codesAgent: "Code", //  коды +

  clientCodesBudget: "clientCodesBudget",
  clientCodesPerson: "clientCodesPerson",
  codeEnum: "codeEnum",

  commercialTerms: "FinCondition", //КУ  +

  commercialTermsContract: "commercialTermsContract",
  financialConditionRule: "financialConditionRule",

  clients: "Client", //клиенты +
  supplier: "Supplier", // поставщик +

  agent: "Agency", //Агенства  +

  supplierService: "supplierService",
  clientContact: "clientContact",

  clientPersonContact: "clientPersonContact",
  contracts: "Contract", // договоры +

  corpPolity: "Policy", // КП  +
  policyRule: "policyRule",

  policyContract: "policyContract",

  creditcard: "creditcard",

  clientPerson: "clientPerson",
  clientPersonContact: "clientPersonContact",
  clientPersonBonusCard: "clientPersonBonusCard",
  clientPersonDocument: "clientPersonDocument",

  personCodeValue: "personCodeValue",

  personePref: "personePref",

  brand: "Brand", // бренд +

  travelers: "Person", //путишествиники +

  visa: "visa", // виза персоны,

  sabreRemark: "sabreRemark",

  importFile: "Import", //импорт +

  // конфиг для субсущностейклиента и агента

  ContactAgency: "ContactAgency", // +
  AddressAgency: "AddressAgency", // +
  AttrAgency: "AttrAgency", // +
  ContactClient: "ContactClient", // +
  AddressClient: "AddressClient", // +
  AttrClient: "AttrClient", // +
  CreditCardClient: "CreditCardClient", // +

  // карта для персоны
  CreditCardPerson: "CreditCardPerson",
  CrmCorp:"CrmCorp",
  CrmHolding:"CrmHolding",
  cfo : "Cfo",

  ReceiverSystem:"ReceiverSystem",
  ReceiverSystems: "ReceiverSystems",
  ConnectDirectory: "ConnectDirectory",

  hrFeed: 'hrFeed',
  HrFeedFields:'HrFeedFields',

  StatisticHrFeed : 'StatisticHrFeed',
  StatisticGetCatalogUnit : 'StatisticGetCatalogUnit',

  VST: "VST",
  roleBlock:"roleBlock"
};
/**
 * список возможных действий пользовате
 */
export const NAME_ACTION = {
  isCreate: "isCreate", // create
  isDelete: "isDelete", // delete
  isEdit: "isEdit", //edit
  isMenu: "isMenu", //showmenu
  isRead: "isRead" // show
};
/**
 * список сравнения
 */
export const LIST_EQUIVALENT = {
  System: "ReferenceSystem"
};
