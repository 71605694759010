<template>
  <sidebar-item :link="{name: $t('Настройки'),  icon: 'vip_icon icon_settings'}">
    <StatisticMenu />
    <sidebar-item :link="{name: $t('Доступы'),  path: `/setting/access` }"></sidebar-item>
    <sidebar-item :link="{name: $t('Пользователи'), icon: 'nc-icon nc-single-02', path: `/user`}"></sidebar-item>
    <sidebar-item :link="{name: $t('Маппинг компаний'),  path: `/setting/importCortes` }"></sidebar-item>
    <sidebar-item :link="{name: $t('Разрешённые договоры'),  path: `/setting/contractorAccess` }"></sidebar-item>
    <token-menu />
  </sidebar-item>
</template>
<script>
import StatisticMenu from "../../v2/module/statisticHrFeed/StatisticMenu.vue";
import TokenMenu from "@/components/v2/module/tokens/TokenMenu.vue";
export default {
  name: "SettingMenu",
  components: {
    TokenMenu,
    StatisticMenu
  }
};
</script>
