import { API_METHODS } from "../../constant";
export const RoleContractApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn,
  _loadFile
) => {
  return {
    [API_METHODS.GET_CONTRACT_LIST_ROLE]: () => {
      let urlReq = `/PersonRoleContract/ForContract/${paramsIn.ContractId}`;
      return _getAll(urlReq);
    },
    [API_METHODS.CREATE_CORTES_CONTRACT_ROLE]: () => {
      let urlReq = `/PersonRoleContract`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_CORTES_CONTRACT_ROLE]: () => {
      let urlReq = `/PersonRoleContract/${paramsIn.personRoleContractId}`;
      return _delete(urlReq);
    },
    [API_METHODS.UPDATE_CORTES_CONTRACT_ROLE]: () => {
      let urlReq = `/PersonRoleContract`;
      return _update(urlReq);
    },
    [API_METHODS.GET_ROLE_PERSON_IN_CONTRACT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Role`;
      return _get(urlReq);
    },
    [API_METHODS.SET_ROLE_PERSON_IN_CONTRACT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Role`;
      return _update(urlReq);
    },
    [API_METHODS.SET_MODE_ACTIVE_ROLE_PERSON_IN_CONTRACT]: () => {
      let urlReq = `/Person/${paramsIn.PersonId}/Role/ChangeRegime`;
      return _update(urlReq);
    },



  };
};
