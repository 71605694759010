import {LOAD_LIST_TABS} from "./components/modules/adminManager/store/type";

export const MAIN_HOST =
  window && window.appConfig && window.appConfig.MAIN_HOST
    ? window.appConfig.MAIN_HOST
    : process.env.VUE_APP_MAIN_HOST;
export const NAME_TOKEN = "main-token";
export const NAME_SITE = "VipService";
export const LANGS = [
  {languageCode: "ru", title: "RU", languageId: 1},
  {languageCode: "en", title: "EN", languageId: 2}
];
export const NAME_LS_DEF_PAGE = "def_page_store";

export const DEFAULT_PER_PAGE = 5;

export const GET_MEMORY_PER_PAGE = () => {
  let mem = localStorage.getItem(NAME_LS_DEF_PAGE);
  let res = mem ? parseInt(mem) : 10;
  return res;
};

export const TYPE_ERRORS = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  DEF_TYPE: ""
};
export const FIELD_LANG_COMPANY = "companyLangDTOList";
export const FIELD_LANG_ENTITY = "refEntityLangDTOList";
export const FIELD_LANG_PERSON = "personLangDTOList";
export const FIELD_LANG_DOCUMENT = "personLangDTOList";
export const FIELD_LANG_CODE = "codeLangDTOList";
export const FIELD_LANG_CODE_ENUM = "codeEnumLangDTOList";
export const FIELD_LANG_POLICY = "policyLangDTOList";
export const FIELD_LANG_POLICY_RULE = "policyRuleLangDTOList";
export const FIELD_LANG_FINANCIAL_CONDITION = "financialConditionLangDTOList";
export const FIELD_LANG_FINANCIAL_CONDITION_RULE =
  "financialConditionRuleLangDTOList";
export const FIELD_LANG_POLITICY_ITEM_RULE = "messageLangDTOList";

export const TYPE_CODE_ENUM = "Enum";

export const API_METHODS = {
  GET_CODE: "GET_CODE",
  GET_ALL_CODE: "GET_ALL_CODE",
  UPDATE_CODE: "UPDATE_CODE",
  CREATE_CODE: "CREATE_CODE",
  DELETE_CODE: "DELETE_CODE",

  GET_CLIENT_CODE: "GET_CLIENT_CODE",

  GET_CODE_ENUM: "GET_CODE_ENUM",
  GET_ALL_CODE_ENUM: "GET_ALL_CODE_ENUM",
  UPDATE_CODE_ENUM: "UPDATE_CODE_ENUM",
  CREATE_CODE_ENUM: "CREATE_CODE_ENUM",
  DELETE_CODE_ENUM: "DELETE_CODE_ENUM",

  GET_CODE_HISTORY: "GET_CODE_HISTORY",
  GET_CODE_ENUM_HISTORY: "GET_CODE_ENUM_HISTORY",

  GET_COMPANY: "GET_COMPANY",
  GET_ALL_COMPANY: "GET_ALL_COMPANY",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  CREATE_COMPANY: "CREATE_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",

  GET_CONTRACT: "GET_CONTRACT",
  GET_ALL_CONTRACT: "GET_ALL_CONTRACT",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",
  CREATE_CONTRACT: "CREATE_CONTRACT",
  DELETE_CONTRACT: "DELETE_CONTRACT",

  GET_PERSON: "GET_PERSON",
  GET_ALL_PERSON: "GET_ALL_PERSON",
  UPDATE_PERSON: "UPDATE_PERSON",
  CREATE_PERSON: "CREATE_PERSON",
  DELETE_PERSON: "DELETE_PERSON",

  GET_USER: "GET_USER",
  GET_ALL_USER: "GET_ALL_USER",
  UPDATE_USER: "UPDATE_USER",
  CREATE_USER: "CREATE_USER",
  DELETE_USER: "DELETE_USER",
  GET_USER_SUBJECTLINK: "GET_USER_SUBJECTLINK",
  GET_USER_SUBLINKS: "GET_USER_SUBLINKS",

  GET_DOCUMENT: "GET_DOCUMENT",
  GET_ALL_DOCUMENT: "GET_ALL_DOCUMENT",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  CREATE_DOCUMENT: "CREATE_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",

  GET_BONUS_CARD: "GET_BONUS_CARD",
  GET_ALL_BONUS_CARD: "GET_ALL_BONUS_CARD",
  UPDATE_BONUS_CARD: "UPDATE_BONUS_CARD",
  CREATE_BONUS_CARD: "CREATE_BONUS_CARD",
  DELETE_BONUS_CARD: "DELETE_BONUS_CARD",

  GET_CONTACT: "GET_CONTACT",
  GET_ALL_CONTACT: "GET_ALL_CONTACT",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  CREATE_CONTACT: "CREATE_CONTACT",
  DELETE_CONTACT: "DELETE_CONTACT",
  GET_CONTACT_HISTORY: "GET_CONTACT_HISTORY",

  GET_ENTITY: "GET_ENTITY",
  GET_ALL_ENTITY: "GET_ALL_ENTITY",
  GET_LIST_ENTITY: "GET_LIST_ENTITY",
  UPDATE_ENTITY: "UPDATE_ENTITY",
  CREATE_ENTITY: "CREATE_ENTITY",
  DELETE_ENTITY: "DELETE_ENTITY",
  LIST_ENTITY: "LIST_ENTITY",

  BASE_V_SELECT_LOAD_DATA: "BASE_V_SELECT_LOAD_DATA",

  // get codes that are not linked to the company
  GET_CODE_NOT_LINKED_TO_COMPANY: "GET_CODE_NOT_LINKED_TO_COMPANY",
  GET_CODE_ENUM_VALUES: "GET_CODE_ENUM_VALUES",

  COMPANY_CONNECT_CODE: "COMPANY_CONNECT_CODE",
  COMPANY_UNCONNECT_CODE: "COMPANY_UNCONNECT_CODE",

  PERSON_GET_HISTORY: "PERSON_GET_HISTORY",

  RETURN_CODE: "RETURN_CODE",
  RETURN_CODE_ENUM: "RETURN_CODE_ENUM",
  RETURN_CONTACT: "RETURN_CONTACT",
  RETURN_PERSON: "RETURN_PERSON",
  CODE_VALUE: "CODE_VALUE",
  DOCUMENT_GET_HISTORY: "DOCUMENT_GET_HISTORY",
  RETURN_DOCUMENT: "DOCUMENT_RETURN",
  RETURN_BONUS_CARD: "BONUS_CARD_RETURN",
  BONUS_CARD_GET_HISTORY: "BONUS_CARD_GET_HISTORY",

  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_ME: "AUTH_ME",
  AUTH_CHECH: "AUTH_CHECH",
  AUTH_REFRESH: "AUTH_REFRESH",
  AUTH_LOGOUT: "AUTH_LOGOUT",

  GET_POLICY: "GET_POLICY",
  GET_ALL_POLICY: "GET_ALL_POLICY",
  UPDATE_POLICY: "UPDATE_POLICY",
  CREATE_POLICY: "CREATE_POLICY",
  DELETE_POLICY: "DELETE_POLICY",

  GET_FINANCIAL_CONDITION: "GET_FINANCIAL_CONDITION",
  GET_ALL_FINANCIAL_CONDITION: "GET_ALL_FINANCIAL_CONDITION",
  UPDATE_FINANCIAL_CONDITION: "UPDATE_FINANCIAL_CONDITION",
  CREATE_FINANCIAL_CONDITION: "CREATE_FINANCIAL_CONDITION",
  DELETE_FINANCIAL_CONDITION: "DELETE_FINANCIAL_CONDITION",

  GET_POLICY_RULE: "GET_POLICY_RULE",
  GET_ALL_POLICY_RULE: "GET_ALL_POLICY_RULE",
  UPDATE_POLICY_RULE: "UPDATE_POLICY_RULE",
  CREATE_POLICY_RULE: "CREATE_POLICY_RULE",
  DELETE_POLICY_RULE: "DELETE_POLICY_RULE",

  GET_FINANCIAL_CONDITION_RULE: "GET_FINANCIAL_CONDITION_RULE",
  GET_ALL_FINANCIAL_CONDITION_RULE: "GET_ALL_FINANCIAL_CONDITION_RULE",
  UPDATE_FINANCIAL_CONDITION_RULE: "UPDATE_FINANCIAL_CONDITION_RULE",
  CREATE_FINANCIAL_CONDITION_RULE: "CREATE_FINANCIAL_CONDITION_RULE",
  DELETE_FINANCIAL_CONDITION_RULE: "DELETE_FINANCIAL_CONDITION_RULE",

  GET_ALL_CHILD_TYPE_CONTACT: "GET_ALL_CHILD_TYPE_CONTACT",
  DELETE_CHILD_TYPE_CONTACT: "DELETE_CHILD_TYPE_CONTACT",
  CREATE_CHILD_TYPE_CONTACT: "CREATE_CHILD_TYPE_CONTACT",
  GET_CHILD_TYPE_UNLINKED_CONTACT: "GET_CHILD_TYPE_UNLINKED_CONTACT",

  GET_ADDRES: "GET_ADDRES",
  GET_ALL_ADDRES: "GET_ALL_ADDRES",
  UPDATE_ADDRES: "UPDATE_ADDRES",
  CREATE_ADDRES: "CREATE_ADDRES",
  DELETE_ADDRES: "DELETE_ADDRES",

  GET_ATTR_ENTITY: "GET_ATTR_ENTITY",
  GET_ALL_ATTR_ENTITY: "GET_ALL_ATTR_ENTITY",
  UPDATE_ATTR_ENTITY: "UPDATE_ATTR_ENTITY",
  CREATE_ATTR_ENTITY: "CREATE_ATTR_ENTITY",
  DELETE_ATTR_ENTITY: "DELETE_ATTR_ENTITY",

  GET_ATTR_VALUE: "GET_ATTR_VALUE",
  GET_ALL_ATTR_VALUE: "GET_ALL_ATTR_VALUE",
  UPDATE_ATTR_VALUE: "UPDATE_ATTR_VALUE",
  CREATE_ATTR_VALUE: "CREATE_ATTR_VALUE",
  DELETE_ATTR_VALUE: "DELETE_ATTR_VALUE",

  GET_TYPE_ATTR_VALUE: "GET_TYPE_ATTR_VALUE",

  GET_ALL_SUPPLIER_SERVICE: "GET_ALL_SUPPLIER_SERVICE",
  CREATE_SUPPLIER_SERVICE: "CREATE_SUPPLIER_SERVICE",
  DELETE_SUPPLIER_SERVICE: "DELETE_SUPPLIER_SERVICE",
  GET_ALL_SUPPLIER_SERVICE_UNLINKED: "GET_ALL_SUPPLIER_SERVICE_UNLINKED",

  GET_PERSONE_CODE_VALUE: "GET_PERSONE_CODE_VALUE",
  GET_ALL_PERSONE_CODE_VALUE: "GET_ALL_PERSONE_CODE_VALUE",
  UPDATE_PERSONE_CODE_VALUE: "UPDATE_PERSONE_CODE_VALUE",
  CREATE_PERSONE_CODE_VALUE: "CREATE_PERSONE_CODE_VALUE",
  DELETE_PERSONE_CODE_VALUE: "DELETE_PERSONE_CODE_VALUE",

  IMPORT_FILE: "IMPORT_FILE",

  COMPANY_GET_HISTORY: "COMPANY_GET_HISTORY",
  RETURN_COMPANY: "RETURN_COMPANY",

  JURNAL_GET: "JURNAL_GET",
  JURNAL_CHANGE_GET: "JURNAL_CHANGE_GET",
  JURNAL_OUT_MES_GET: "JURNAL_OUT_MES_GET",

  LOAD_LIST_TABS: "LOAD_LIST_TABS",
  LOAD_TABS_DATA: "LOAD_TABS_DATA",

  GET_ADMIN_ROUTE_MANAGER: "GET_ADMIN_ROUTE_MANAGER",
  GET_ALL_ADMIN_ROUTE_MANAGER: "GET_ALL_ADMIN_ROUTE_MANAGER",
  UPDATE_ADMIN_ROUTE_MANAGER: "UPDATE_ADMIN_ROUTE_MANAGER",
  CREATE_ADMIN_ROUTE_MANAGER: "CREATE_ADMIN_ROUTE_MANAGER",
  DELETE_ADMIN_ROUTE_MANAGER: "DELETE_ADMIN_ROUTE_MANAGER",

  GET_CREDITCARD: "GET_CREDITCARD",
  GET_ALL_CREDITCARD: "GET_ALL_CREDITCARD",
  UPDATE_CREDITCARD: "UPDATE_CREDITCARD",
  CREATE_CREDITCARD: "CREATE_CREDITCARD",
  DELETE_CREDITCARD: "DELETE_CREDITCARD",

  EVENTLOG_GET: "EVENTLOG_GET",
  GET_ALL_REF_ENTITY: "GET_ALL_REF_ENTITy",

  GET_ROLE_USER: "GET_ROLE_USER",
  USER_UPDATE_ROLE: " USER_UPDATE_ROLE",
  //-----------------------------------
  GET_PREF_DATA: "GET_PREF_DATA",
  GET_ALL_PREF_DATA: "GET_ALL_PREF_DATA",
  UPDATE_PREF_DATA: "UPDATE_PREF_DATA",
  CREATE_PREF_DATA: "CREATE_PREF_DATA",
  DELETE_PREF_DATA: "DELETE_PREF_DATA",
  //-----------------------------------

  //-----------------------------------
  GET_TREVELRS: "GET_TREVELRS",
  GET_ALL_TREVELRS: "GET_ALL_TREVELRS",
  UPDATE_TREVELRS: "UPDATE_TREVELRS",
  CREATE_TREVELRS: "CREATE_TREVELRS",
  DELETE_TREVELRS: "DELETE_TREVELRS",
  //-----------------------------------

  //-----------------------------------
  GET_VISA: "GET_VISA",
  GET_ALL_VISA: "GET_ALL_VISA",
  UPDATE_VISA: "UPDATE_VISA",
  CREATE_VISA: "CREATE_VISA",
  DELETE_VISA: "DELETE_VISA",
  //-----------------------------------

  //----------------------------------
  GET_CODE_NOT_LINKED_TO_CONTRACT: "GET_CODE_NOT_LINKED_TO_CONTRACT",
  CONTRACT_CONNECT_CODE: "CONTRACT_CONNECT_CODE",
  CONTRACT_UNCONNECT_CODE: "CONTRACT_UNCONNECT_CODE",
  CONTRACT_CODE_VALUE: "CONTRACT_CODE_VALUE",
  //----------------------------------

  //----------------------------------
  GET_SABRE_REMARK: "GET_SABRE_REMARK",
  GET_ALL_SABRE_REMARK: "GET_ALL_SABRE_REMARK",
  UPDATE_SABRE_REMARK: "UPDATE_SABRE_REMARK",
  CREATE_SABRE_REMARK: "CREATE_SABRE_REMARK",
  DELETE_SABRE_REMARK: "DELETE_SABRE_REMARK",
  //----------------------------------

  CREATE_RULE_ITEM_TABLE: "CREATE_RULE_ITEM_TABLE",
  UPDATE_RULE_ITEM_TABLE: "UPDATE_RULE_ITEM_TABLE",
  GET_NEWRULE_ITEM_TABLE: "GET_NEWRULE_ITEM_TABLE",
  DELETE_RULE_ITEM_TABLE: "DELETE_RULE_ITEM_TABLE",

  //-----------------------------
  GET_STRUCTORE_CODE_CONTRACT: "GET_STRUCTORE_CODE_CONTRACT",
  CREATE_STRUCTORE_CODE_CONTRACT: "CREATE_STRUCTORE_CODE_CONTRACT",
  UPDATE_STRUCTORE_CODE_CONTRACT: "UPDATE_STRUCTORE_CODE_CONTRACT",
  DELETE_STRUCTORE_CODE_CONTRACT: "DELETE_STRUCTORE_CODE_CONTRACT",
  GET_ALL_STRUCTORE_CODE_CONTRACT: "GET_ALL_STRUCTORE_CODE_CONTRACT",

  //---------------
  GET_CONTRACT_BUDGET_CODE_VALUE: "GET_CONTRACT_BUDGET_CODE_VALUE",
  UPDATE_CREATE_CONTRACT_BUDGET_CODE_VALUE:
    "UPDATE_CREATE_CONTRACT_BUDGET_CODE_VALUE",
  DELETE_CONTRACT_BUDGET_CODE_VALUE: "DELETE_CONTRACT_BUDGET_CODE_VALUE",

  // ----------------
  DELETE_CODE_CONTRACT: "DELETE_CODE_CONTRACT",

  GET_CONTRACT_CODE: "GET_CONTRACT_CODE",
  // ----------------
  GET_COUNTERPARTY: "GET_COUNTERPARTY",
  UPDATE_COUNTERPARTY: "UPDATE_COUNTERPARTY",
  CREATE_COUNTERPARTY: "CREATE_COUNTERPARTY",
  DELETE_COUNTERPARTY: "DELETE_COUNTERPARTY",
  GET_ALL_COUNTERPARTIES: "GET_ALL_COUNTERPARTIES",

  // ----------------
  GET_COUNTERPARTY_BANK_DETAILS: "GET_COUNTERPARTY_BANK_DETAILS",
  UPDATE_COUNTERPARTY_BANK_DETAILS: "UPDATE_COUNTERPARTY_BANK_DETAILS",
  CREATE_COUNTERPARTY_BANK_DETAILS: "CREATE_COUNTERPARTY_BANK_DETAILS",
  DELETE_COUNTERPARTY_BANK_DETAILS: "DELETE_COUNTERPARTY_BANK_DETAILS",
  GET_ALL_COUNTERPARTIES_BANK_DETAILS: "GET_ALL_COUNTERPARTIES_BANK_DETAILS",

  // ----------------
  GET_AGREEMENTS: "GET_AGREEMENTS",
  // UPDATE_AGREEMENTS: "UPDATE_AGREEMENTS",
  // CREATE_AGREEMENTS: "CREATE_AGREEMENTS",
  DELETE_AGREEMENTS: "DELETE_AGREEMENTS",
  GET_ALL_AGREEMENTS: "GET_ALL_AGREEMENTS",

  // ----------------
  GET_COUNTERPARTY_AGREEMENT: "GET_COUNTERPARTY_AGREEMENT",
  GET_COUNTERPARTY_ALL_AGREEMENTS: "GET_COUNTERPARTY_ALL_AGREEMENTS",
  CREATE_COUNTERPARTY_AGREEMENT: "CREATE_COUNTERPARTY_AGREEMENT",
  UPDATE_AGREEMENTS: "UPDATE_COUNTERPARTY_AGREEMENT",
  CREATE_AGREEMENTS: "CREATE_COUNTERPARTY_AGREEMENT",
  // DELETE_AGREEMENTS:"DELETE_COUNTERPARTY_AGREEMENT",
  // ----------------
  GET_SHEDULE: "GET_SHEDULE",
  UPDATE_SHEDULE: "UPDATE_SHEDULE",
  CREATE_SHEDULE: "CREATE_SHEDULE",
  DELETE_SHEDULE: "DELETE_SHEDULE",
  GET_ALL_SHEDULE: "GET_ALL_SHEDULE",
  GET_SHEDULE_SETTINGS: "GET_SHEDULE_SETTINGS",
  UPDATE_SHEDULE_SETTINGS: "UPDATE_SHEDULE_SETTINGS",
  CREATE_SHEDULE_SETTINGS: "CREATE_SHEDULE_SETTINGS",
  //---------------------------------
  GET_DATA_MULTISELEC: "GET_DATA_MULTISELEC",

  // ---cfo----
  GET_CFO: "GET_CFO",
  UPDATE_CFO: "UPDATE_CFO",
  CREATE_CFO: "CREATE_CFO",
  DELETE_CFO: "DELETE_CFO",
  GET_ALL_CFO: "GET_ALL_CFO",

  // --receiverSystems---
  GET_RECEIVER_SYSTEM: "GET_RECEIVER_SYSTEM",
  UPDATE_RECEIVER_SYSTEM: "UPDATE_RECEIVER_SYSTEM",
  CREATE_RECEIVER_SYSTEM: "CREATE_RECEIVER_SYSTEM",
  DELETE_RECEIVER_SYSTEM: "DELETE_RECEIVER_SYSTEM",
  GET_ALL_RECEIVER_SYSTEM: "GET_ALL_RECEIVER_SYSTEM",


  // --connectDirectory---
  GET_CONNECT_DIRECTORY: "GET_CONNECT_DIRECTORY",
  UPDATE_CONNECT_DIRECTORY: "UPDATE_CONNECT_DIRECTORY",
  CREATE_CONNECT_DIRECTORY: "CREATE_CONNECT_DIRECTORY",
  DELETE_CONNECT_DIRECTORY: "DELETE_CONNECT_DIRECTORY",
  GET_ALL_CONNECT_DIRECTORY: "GET_ALL_CONNECT_DIRECTORY",


  //---history --
  GET_HISTORY_AGREEMNTS: "GET_HISTORY_AGREEMNTS",
  GET_HISTORY_CFO: "GET_HISTORY_CFO",
  GET_HISTORY_COUNTERPARTY: "GET_HISTORY_COUNTERPARTY",
  GET_HISTORY_COUNTERPARTY_BANK_DETAILS: "GET_HISTORY_COUNTERPARTY_BANK_DETAILS",

  //--crmCorp --
  GET_ALL_CRM_CORP_CONTRAGENT: "GET_ALL_CRM_CORP_CONTRAGENT",

  GET_AGREEMENTS_FOR_CRM_CORP: "GET_AGREEMENTS_FOR_CRM_CORP",
  //--hrfeed--

  GET_ALL_HR: "GET_ALL_HR",
  GET_HR: "GET_HR",
  GET_ALL_HR_LOG: "GET_ALL_HR_LOG",
  GET_HR_LOG: "GET_HR_LOG",
  GET_HR_SETTING: "GET_HR_SETTING",
  UPDATE_HR_SETTING: "UPDATE_HR_SETTING",
  HR_LOAD_FILE: "HR_LOAD_FILE",
  HR_CHECK_FILE: "HR_CHECK_FILE",
  UPDATE_HR_FIELD: "UPDATE_HR_FIELD",

  //----HrFeedFields---///
  GET_HrFeedFields: "GET_HrFeedFields",
  UPDATE_HrFeedFields: "UPDATE_HrFeedFields",
  CREATE_HrFeedFields: "CREATE_HrFeedFields",
  DELETE_HrFeedFields: "DELETE_HrFeedFields",
  GET_ALL_HrFeedFields: "GET_ALL_HrFeedFields",

  //-- statistichrfeed -- //
  GET_ALL_STATISTIC_HR_FEED: "GET_ALL_STATISTIC_HR_FEED",
  GET_STATISTIC_HR_FEED: "GET_STATISTIC_HR_FEED",

  GET_ALL_STATISTIC_GetCatalogUnit: "GET_ALL_STATISTIC_GetCatalogUnit",
  GET_STATISTIC_GetCatalogUnit: "GET_STATISTIC_GetCatalogUnit",

  GET_ALL_AGREEMENTS_COMMON: "GET_ALL_AGREEMENTS_COMMON",
  GET_ALL_COUNTERPARTY_COMMON: "GET_ALL_COUNTERPARTY_COMMON",

  SAVE_STATISTIC: 'SAVE_STATISTIC',
  GET_ALL_STATISTIC_HR_FEED_EXPORT: "GET_ALL_STATISTIC_HR_FEED_EXPORT",
  GET_STATISTIC_HR_FEED_EXPORT: "GET_STATISTIC_HR_FEED_EXPORT",
  GET_LOAD_STATISTIC: "GET_LOAD_STATISTIC",

  //-- Corteos synk -- //
  GET_SYNC_CORTEOS_ORG: 'GET_SYNC_CORTEOS_ORG',
  UPDATE_SYNC_CORTEOS_ORG: 'UPDATE_SYNC_CORTEOS_ORG',
  CREATE_SYNC_CORTEOS_ORG: 'CREATE_SYNC_CORTEOS_ORG',
  DELETE_SYNC_CORTEOS_ORG: 'DELETE_SYNC_CORTEOS_ORG',
  GET_SYNC_ALL_CORTEOS_ORG: "GET_SYNC_ALL_CORTEOS_ORG",
  CUSTOM_SEARHCH_FOR_SYNC_CORTEOS_ORG: "CUSTOM_SEARHCH_FOR_SYNC_CORTEOS_ORG",
  ACCESS_ORG: 'ACCESS_ORG',
  GET_ACCESS_ORG: 'GET_ACCESS_ORG',
  CUSTOM_SEARCH_ORG_FOR_ADD_USER_TOKEN:"CUSTOM_SEARCH_ORG_FOR_ADD_USER_TOKEN",

  GENERATE_TOKEN: 'GENERATE_TOKEN',
  GET_HrFeedUserToken: 'GET_HrFeedUserToken',
  GET_UserCompany:'GET_UserCompany',
  GET_CreateAccesCompany:'GET_CreateAccesCompany',
  DELETE_AccesCompany:'DELETE_AccesCompany',
  CREATE_AccesCompany: 'CREATE_AccesCompany',

  GET_TOKENS: "GET_TOKENS",
  LOCK_TOKEN: "LOCK_TOKEN",
  UPDATE_LIVE_TOKEN: "UPDATE_LIVE_TOKEN",
  GET_ACCESS_ORG_BY_USER_ID: 'GET_ACCESS_ORG_BY_USER_ID',
  GET_ACCESS_USER_BY_ORG_ID: 'GET_ACCESS_USER_BY_ORG_ID',

  GET_CONTRACT_LIST_ROLE: 'GET_CONTRACT_LIST_ROLE',
  CREATE_CORTES_CONTRACT_ROLE: "CREATE_CORTES_CONTRACT_ROLE",
  DELETE_CORTES_CONTRACT_ROLE: "DELETE_CORTES_CONTRACT_ROLE",
  UPDATE_CORTES_CONTRACT_ROLE:"UPDATE_CORTES_CONTRACT_ROLE",
  GET_ROLE_PERSON_IN_CONTRACT: "GET_ROLE_PERSON_IN_CONTRACT",
  SET_ROLE_PERSON_IN_CONTRACT: "SET_ROLE_PERSON_IN_CONTRACT",
  SET_MODE_ACTIVE_ROLE_PERSON_IN_CONTRACT: "SET_MODE_ACTIVE_ROLE_PERSON_IN_CONTRACT"
};

export const PAGE_MODE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  SHOW: "SHOW"
};

export const TYPE_IMPORT_FILE = {
  COMPANY: "client",
  PERSON: "person",
  CODE: "code",
  BRAND: "brand",
  AGENCY: "agency",
  CONTRACT: "contract"
};

export const API_URLS = {
  registration: "/v2/auth/registration",
  login: "/auth/signin",
  me: "/auth/me",
  places: `/v1/places`,
  editProfile: "/v2/profile"
};

export const CATEGORY_MENU = {
  COMMON: "Common",
  SYSYTEM: "System",
  GEOGRAPHY: "Geography"
};

export const VERSION = 2.1;

export const CODE_TYPE_DOCUMENT_PASPORT_RF = "Passport";
export const CODE_TYPE_DOCUMENT_ZAGRANPASPORT = "ForeignPassport";
export const CODE_TYPE_DOCUMENT_BIRTH_CERTIFICATE = "BirthCertificate";
export const CODE_TYPE_DOCUMENT_NATIONAL_PASSPORT = "NationalPassport";

export const CODE_TYPE_CONTACT_EMAIL = "Email";
export const CODE_TYPE_CONTACT_PHONE = "Other";
export const CODE_TYPE_CONTACT_MOB_PHONE = "Mobile";

export const CODE_NO_CHOUSE_PREF = "NULL";

export const TOKEN_TIME_START = "TOKEN_TIME_START";
export const TIME_MS_EXPIRED = 1000 * 60 * 60 * 24 - 1000; //токен на день - 1с

export const GLOBAL_DATE_FORMAT_FOR_PICKER = "dd-MM-yyyy";
export const GLOBAL_DATE_FORMAT = "DD-MM-YYYY";
export const GLOBAL_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
export const CfoType = {
  Cfo1BusinessLevel: "Cfo1BusinessLevel",
  Cfo2BusinessLevel: "Cfo2BusinessLevel",
  Cfo: "Cfo",
  Cfo4BusinessLevel: "Cfo4BusinessLevel",
  CfoHoldings: "CfoHoldings"
};
