<template>
  <div>
    <card :title="title">
      <!-- <show-code :code="loadData"></show-code> -->
      <dynamic-generator-form :form="loadData" v-if="loadData" :configData="configData"
                              :isCreateForm="isCreateForm" :pageMode="pageMode"></dynamic-generator-form>
      <slot/>
      <slot name="block4"></slot>
      <slot name="block3"></slot>
      <slot name="block2"></slot>
    </card>
  </div>
</template>
<script>
  import convertJsonShemeToData from "@/helpers/generatorForm/convertJsonShemeToData.js"
  import DynamicGeneratorForm from "./modules/generator/DynamicGeneratorForm.vue";
  import ShowCode from "./shared/ShowCode.vue";
  import {SET_MAIN_SEARCH_ENTITY} from '../store/type'
  export default {
    components: {
      ShowCode,
      DynamicGeneratorForm
    },
    name: 'TheEditPage',
    props: {
      title: {type: String, default: ''},
      scheme: {required: true},  // схема данных
      isEntity: {type: Boolean, default: false}, //указывает что это справочник,
      loadData: {type: Object},
      isCreateForm: {type: Boolean, default: false},
      pageMode:  {type: String, required: true},
    },
    created() {
      let ParseData = this.scheme
      this.configData = ParseData.configForm
      this.$store.commit( SET_MAIN_SEARCH_ENTITY, '')
    },
    data() {
      return {
        configData: null,
        form: null,
      }
    }
  }
</script>
