<template>
  <div m="4">
    <el-table :data="dataExpand" :border="true">
      <el-table-column :label="$t('ИНН')" prop="companyInn"/>
      <el-table-column :label="$t('КПП')" prop="companyKpp"/>
      <el-table-column :label="$t('Название')" prop="companyName"/>
      <el-table-column :label="$t('Actions')" width="100">
        <template slot-scope="props">
          <button
            class="btn-danger btn-simple btn-link tableBtn"
            @click="beforeDelete(props.row)"
          ><i
            class="table_icon icon_delete"
          ></i
          ></button>
        </template>

      </el-table-column>
    </el-table>
    <div v-if="modeAdd" class="addBlockCompany">

      <SelectorBlock
        classElement="blockOrg"
        labelText="Организация"
        url="/RefEntity/DropDownList/Client"

        @selectCode="setOrgId"
        labelSelect="entityName"
        :form="loadData"
        nameFiels="organizationId"
        :use-custom-function-search="true"
        :customFunctionSearch="customSearhc"
      />


      <l-button @click="CreateLink()" type="success" wide>
            <span class="btn-label"
            >Добавить<i class="el-icon-plus"></i
            ></span>
      </l-button>
      <l-button @click="cancel()" type="warning" wide>
            <span class="btn-label"
            >Закрыть</span>
      </l-button>
    </div>

    <div class="blockBtns" >
    <l-button @click="addCompany()" type="success" wide v-if="!modeAdd && !showTok">
            <span class="btn-label"
            >Добавить<i class="el-icon-plus"></i
            ></span>
    </l-button>
    <l-button @click="showToken()" type="danger" wide v-if="!modeAdd && !showTok">
            <span class="btn-label"
            >Сгенерировать Токен<i class="el-icon-plus"></i
            ></span>
    </l-button>
    </div>

    <div v-if="showTok" class="blockTokenGen">

      <el-input v-model="token" readonly  placeholder="Please input"  class="tokenInput"/>
      <l-button @click="CreateToken()" type="success" wide>
            <span class="btn-label"
            >Сгенерировать Токен<i class="el-icon-plus"></i
            ></span>
      </l-button>
      <l-button @click="cancel()" type="warning" wide>
            <span class="btn-label"
            >Закрыть</span>
      </l-button>

    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import {ApiService} from "@/util/ApiService";
import {API_METHODS} from "@/constant";
import SelectorBlock from "@/components/v2/module/person/prefData/SelectorBlock.vue";
import swal from "sweetalert2";

export default {
  props: {
    row: {
      type: Object, default: () => {
      }
    },
    dataExpand: {type: Array, default: []},
    updateData: {type: Function}
  },
  components: {
    SelectorBlock,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      token:'',
      modeAdd: false,
      showTok: false,
      loadData: {
        organizationId: 0,
      },
    };
  },
  methods: {
    cancel() {
      this.modeAdd = false;
      this.showTok = false;
    },
    setOrgId(val, isModeInit = false) {
      this.loadData.organizationId = val.entityId;
    },

    beforeDelete(row) {
      swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false,
      }).then((res) => {
        if (res.value && res.value == true) {
          this.handleDelete(row);
        }
      });
    },


    async handleDelete(row) {
      const params = {
        self: this,
        userCompanyId: row.userCompanyId,
        query: {
          lang: this.$store.state.common.lang
        }
      };
      await ApiService(API_METHODS.DELETE_AccesCompany, params);
      this.updateData(this.row);

    },

    async CreateLink() {
      const params = {
        self: this,
        saveData:
          {
            userId: this.row.userId,
            subjectCompanyId: this.loadData.organizationId,
          },
        query: {
          lang: this.$store.state.common.lang
        }
      };
      await ApiService(API_METHODS.CREATE_AccesCompany, params);
      this.modeAdd = false;
      this.updateData(this.row);
    },


    toggleExpand(row) {
      this.$set(row, 'expanded', !row.expanded);
    },
    addCompany() {
      this.modeAdd = true;
    },
    async customSearhc(search = null, id = null) {
      try {
        const params = {
          self: this,
          search: search ? search : '',
          id,
          lang: this.$store.state.common.lang,
        };
        this.load = true;
        let res
        if (id) {
          res = this.memoryEdit;
        } else {
          res = await ApiService(API_METHODS.CUSTOM_SEARCH_ORG_FOR_ADD_USER_TOKEN, params);
          res.object = res.object ? res.object.map(item => {
            return {
              entityCode: null,
              entityId: item.id,
              entityName: item.name,
              languageId: 0
            }
          }) : []
        }
        this.load = false;
        return res
      } catch (err) {
        this.load = false;
      }
      return [];
    },
    showToken() {
      this.showTok = true
    },
    async CreateToken() {
      const params = {
        self: this,
        saveData:
          {
            forUserId: this.row.userId,
          },
        query: {
          lang: this.$store.state.common.lang
        }
      };

     const res= await ApiService(API_METHODS.GET_HrFeedUserToken, params);
     debugger
     console.log(res);
     if (res.errors && res.errors.length > 0) {
       this.token=res.errors[0];
     } else {
       this.token='токен создан';
     }

    }
  },
};

</script>


<style scoped>

</style>
