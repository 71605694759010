import { API_METHODS } from "../../constant";
export const tokenApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn
) => {
  return {
    [API_METHODS.GET_TOKENS]: () => {
      let urlReq = `/HrFeedUserToken`;
      return _getAll(urlReq, paramsIn.dopParams);
    },
    [API_METHODS.LOCK_TOKEN]: () => {
      let urlReq = `/HrFeedUserToken/revoke/${paramsIn.token}`;
      return _delete(urlReq);
    },
    [API_METHODS.UPDATE_LIVE_TOKEN]: () => {
      let urlReq = `/HrFeedUserToken/Extends`;
      return _create(urlReq);
    },
    [API_METHODS.GET_ACCESS_ORG_BY_USER_ID]: () => {
      let urlReq = `/UserCompany/ByUser/${paramsIn.UserId}`;
      return _getAll(urlReq);
    },

    [API_METHODS.GET_ACCESS_USER_BY_ORG_ID]: () => {
      let urlReq = `/UserCompany/ByCompany/${paramsIn.CompanyId}`;
      return _getAll(urlReq, paramsIn.dopParams?paramsIn.dopParams:{} );
    },
  };
};


// GET_TOKENS: "GET_TOKENS",
//   LOCK_TOKEN: "LOCK_TOKEN",
//   UPDATE_LIVE_TOKEN: "UPDATE_LIVE_TOKEN",
