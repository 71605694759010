import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
// Pages
import Login from 'src/pages/Dashboard/Pages/Login.vue'
// Components pages

//Generator
const Generator = () =>
    import('src/pages/Dashboard/Generator/Generator.vue')
    import Icons from 'src/pages/Dashboard/Components/Icons.vue'
let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}
let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [{
    path: 'icons',
    name: 'Icons',
    component: Icons

  }]
}
let generatorMenu = {
  path: '/generator',
  component: DashboardLayout,
  redirect: '/generator/generator',
  children: [{
    path: 'generator',
    name: 'Generator Forms',
    component: Generator
  }]
}

import company from '../../src/components/modules/company/route'
import code from '../../src/components/modules/code/route'
import contract from '../../src/components/modules/contract/route'
import settings from '../components/modules/setting/route'
import importFile from "../components/modules/importData/route"
import bus from "../components/modules/bus/route"
import adminManager from "../components/modules/adminManager/route"
import eventlog from "../components/modules/eventLog/route"

//v2
import entityv2 from "../components/v2/module/entity/route";
import attrEntityv2 from "../components/v2/module/attrEntity/route";
import personv2 from "../components/v2/module/person/route";
import personCodeVal2 from "../components/v2/module/personCodeValue/route";
import travel2 from "../components/v2/module/travelers/route";
import agencies from "../components/v2/module/agencies/route";
// import contractv2 from "../components/v2/module/contract/route" //роутеру что то ненравится типо два контакта говорит
import userV2 from "../components/v2/module/user/route"
import brand2 from "../components/v2/module/brand/routeBrand";
import client2 from "../components/v2/module/client/routeClient";
import supplier2 from "../components/v2/module/supplier/routeSupplier";
import contactv2 from "../components/v2/module/contact/routeContact";
import corpPolicy2 from "../components/v2/module/corpPolicy/route";
import commercialTerms2 from "../components/v2/module/commercialTerms/route";
import routeAddresv2 from "../components/v2/module/address/routeAddres";
import routeAttrValue2 from "../components/v2/module/attrValue/routeAttrValue";
// новые апишки
import agreements from "../components/v2/module/agreements/routeAgreements";
import counterparties from "../components/v2/module/counterparties/routeCounterparties";
import counterpartiesBankDetails from "../components/v2/module/counterpartiesBankDetails/routeCbd";
import shedule from "../components/v2/module/shedule/routeShedule";
//cfo
import cfo from "../components/v2/module/cfoCommon/cfo/routeCfo"

import routeReceiverSystem from "../components/v2/module/receiverSystem/receiverSystem/routeReceiverSystem";
import routeConnectDirectory from  "../components/v2/module/receiverSystem/connectDirectory/routeConnectDirectory";
import routeCrmCorpContragent from "../components/v2/module/crmCorp/CrmCorpContragent/routeCrmCorpContragent"


import routeHrFeed from "../components/v2/module/HrFeed/routeHrFeed"
import routeHrFeedLog from "../components/v2/module/HrFeedLog/routeHrFeedLog"
import routeAgreementsCFOCopr from "../components/v2/module/agreementsCFOCorp/routeAgreementsCFOCopr.js";

import routeHrFeedFields from "../components/v2/module/HrFeedFields/routeHrFeedFields"

import routeStatisticHrFeed from "@/components/v2/module/statisticHrFeed/routeStatisticHrFeed";
import routeStatisticGetCatalogUnit from "@/components/v2/module/statisticGetCatalogUnit/routeStatisticGetCatalogUnit";
import routeVST from "@/components/v2/module/vstModule/vstAgreements/routeVST";
import routeStatisticHrFeedExport from "@/components/v2/module/statisticHrFeed/routeStatisticHrFeedExport";
import tokenRoutes from "@/components/v2/module/tokens/tokenRoutes";

const routes = [{
    path: '/',
    redirect: '/admin/overview'
  },
  generatorMenu,
  company,
  code,
  contract,
  settings,
  importFile,
  bus,
  adminManager,
  eventlog,
  componentsMenu,
  loginPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [{
      path: 'overview',
      name: 'Overview',
      component: Overview
    }]
  },
  //v2
  entityv2,
  attrEntityv2,
  personv2,
  personCodeVal2,
  travel2,
  userV2,
  agencies,
  // contractv2,
  brand2,
  client2,
  supplier2,
  contactv2,
  corpPolicy2,
  commercialTerms2,
  routeAddresv2,
  {
    path: '*',
    component: NotFound
  },
  routeAttrValue2,
  counterparties,
  counterpartiesBankDetails,
  agreements,
  shedule,
  cfo,
  routeReceiverSystem,
  routeConnectDirectory,
  routeCrmCorpContragent,

  routeAgreementsCFOCopr,
  routeHrFeed,
  routeHrFeedLog,
  routeHrFeedFields,
  routeStatisticHrFeed,
  routeStatisticGetCatalogUnit,
  routeVST,
  routeStatisticHrFeedExport,
  tokenRoutes
]

export default routes
