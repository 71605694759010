import { API_METHODS } from "../../constant";
export const hrFeedApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn,
  _loadFile
) => {
  return {
    [API_METHODS.GET_HR]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Fields/${paramsIn.FieldId}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_HR]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Fields`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_HR_FIELD]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Fields`;
      return _update(urlReq);
    },




    [API_METHODS.GET_HR_LOG]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Logs/${paramsIn.LogId}`;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_HR_LOG]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Logs`;
      return _getAll(urlReq);
    },
    [API_METHODS.GET_HR_SETTING]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Settings`;
      return _get(urlReq);
    },
    [API_METHODS.UPDATE_HR_SETTING]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Settings`;
      return _update(urlReq);
    },

    [API_METHODS.HR_LOAD_FILE]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Process`;
      return _loadFile(urlReq);
    },
    [API_METHODS.HR_CHECK_FILE]: () => {
      let urlReq = `/${paramsIn.SubjectId}/HrFeed/Check`;
      return _loadFile(urlReq);
    },


    [API_METHODS.GET_HrFeedUserToken]: () => {
      let urlReq = `/HrFeedUserToken`;
      return _create(urlReq);
    },
    [API_METHODS.GET_UserCompany]: () => {
      let urlReq = `/UserCompany/ByUser/${paramsIn.userId}`;
      return _get(urlReq);
    },
    // [API_METHODS.GET_UserCompany]: () => {
    //   let urlReq = `/UserCompany/ByCompany/${paramsIn.userId}`;
    //   return _get(urlReq);
    // },
  // /api/UserCompany/ByCompany/{CompanyId}



    [API_METHODS.GET_CreateAccesCompany]: () => {
      let urlReq = `/UserCompany/${paramsIn.userId}`;
      return _get(urlReq);
    },
    [API_METHODS.DELETE_AccesCompany]: () => {
      let urlReq = `/UserCompany/${paramsIn.userCompanyId}`;
      return _delete(urlReq);
    },
    [API_METHODS.CREATE_AccesCompany]: () => {
      let urlReq = `/UserCompany`;
      return _create(urlReq);
    },




  };
};
